import React from 'react';
import './Person.css';

function Person() {

  return (
    <div className='person'>
      <h3 className='main__subtitle'>Личный кабинет</h3>
      
    </div>
  );
}

export default Person;